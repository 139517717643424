<sbx-alert-banner *ngIf="error$ | async" [error]="true" [customText]="error$ | async" />

<div class="sbx-flex" *ngIf="!isVerified">
  <sbx-form
    #emailFormRef
    [form]="emailForm"
    [formFields]="emailFormFields"
    [model]="emailFormModel"
    (submit)="submitEmail()"
    (modelChange)="modelChange.emit($event.model[$event.changeKey])"
  />

  <sbx-send-verification-button
    [model]="model"
    [loading]="loading$ | async"
    (click)="emailFormRef.onManualSubmit()"
  />

  <i class="sbx-sent" *ngIf="showSent">Sent</i>
</div>

<div class="sbx-flex sbx-verified" *ngIf="isVerified">
  <div>
    <span>email&#64;</span><b>{{ model.split('@')[1] || model }}</b>
  </div>

  <sbx-button
    *ngIf="isVerified"
    icon="delete"
    title="Reset"
    [loading]="loading$ | async"
    (click)="submitRemove()"
  />
</div>

<sbx-alert-banner
  *ngIf="warning$ | async"
  [warning]="true"
  [customText]="warning$ | async"
/>

<div class="sbx-flex" *ngIf="!isVerified">
  <sbx-form
    #codeFormRef
    [form]="codeForm"
    [formFields]="codeFormFields"
    [model]="codeFormModel"
    (submit)="submitCode()"
  />

  <sbx-button
    name="verificationButton"
    icon="key"
    title="Verify Code"
    [loading]="loading$ | async"
    (click)="codeFormRef.onManualSubmit()"
  />
</div>
