import { Component, Input, TemplateRef } from '@angular/core';

export type ISbxPropertiesList = ISbxProperty[];

interface ISbxProperty {
  key: string;
  value: string | unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  template?: TemplateRef<any>;
  popoverTitle?: string;
  popoverContent?: string;
  subfield?: number;
}

/**
 *  Shoobx Properties list
 *  (in order to use values with templates in angularjs pages, see
 *  SbxPropertiesListComponentTemplated)
 *
 *  @ngdoc component
 *  @name sbx-properties-list/component:SbxPropertiesListComponent
 *  @description
 *  component for displaying list of properties in desired format
 *
 *
 *  @param items - list of key-value pairs
 *  @param layout - vertical or horizontal listing
 *  @param itemStyle - item style
 *  @param listTitle - string text for list title
 *  @param isSubList - boolean to specify the style of the title - main or sub
 *  @param dividerIndexes - an array with indexes for a divider to apply
 *
 *  Usage:
 *    @Component({
 *      template: `
 *        <sbx-properties-list
 *          [items]="items"
 *          listTitle="Title Here"
 *          itemStyle="lite"
 *          layout="horizontal"
 *          [isSubList]="false"
 *          ></sbx-properties-list>
 *      `,
 *    })
 */
@Component({
  selector: 'sbx-properties-list',
  templateUrl: './sbx-properties-list.component.html',
  styleUrls: ['sbx-properties-list.component.scss'],
})
export class SbxPropertiesListComponent {
  @Input() items: ISbxPropertiesList;
  @Input() layout: 'horizontal' | 'vertical' = 'vertical';
  @Input() itemStyle: 'bold' | 'lite' | 'lite-stacked' | 'normal' = 'lite';
  @Input() listTitle: string = null;
  @Input() isSubList = false;
  @Input() dividerIndexes: number[] = null;
}
