import angular from 'angular';
import { Map, List } from 'immutable';

/**
 * @ngdoc object
 * @name sb.lib.stakeholder:StakeholderEvents
 *
 * @description
 * A model for an entities StakeholderEvents.
 */
export const StakeholderEvents = [
  '$q',
  'SimpleHTTPWrapper',
  'BackendLocation',
  function ($q, SimpleHTTPWrapper, BackendLocation) {
    const STAKEHOLDERS_URL = BackendLocation.entity('1') + 'stakeholders';
    const NOTE_PART = 'stakeholder-notes';

    return {
      /**
       * @ngdoc method
       * @name deleteStakeholderNote
       * @methodOf sb.lib.stakeholder.StakeholderEvents
       *
       * @description
       * Saves the note of the note object has an noteId, updates the existing object
       *
       * @param {string} stakeholderId Stakeholder ID
       * @param {object} note
       *
       * @returns {promise} Promise that resolves with the note object
       */
      deleteStakeholderNote(stakeholderId, note) {
        return SimpleHTTPWrapper(
          {
            method: 'DELETE',
            url: `${STAKEHOLDERS_URL}/${stakeholderId}/${NOTE_PART}`,
            data: { noteId: note.noteId },
          },
          'Failed to save Note',
        );
      },

      /**
       * @ngdoc method
       * @name saveStakeholderNote
       * @methodOf sb.lib.stakeholder.StakeholderEvents
       *
       * @description
       * Saves the note of the note object has an noteId, updates the existing object
       *
       * @param {string} stakeholderId Stakeholder ID
       * @param {object} note
       *
       * @returns {promise} Promise that resolves with the note object
       */
      saveStakeholderNote(stakeholderId, note) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: `${STAKEHOLDERS_URL}/${stakeholderId}/${NOTE_PART}`,
            data: note,
          },
          'Failed to save Note',
        );
      },
    };
  },
];

/**
 * @ngdoc object
 * @name sb.lib.stakeholder:Stakeholders
 *
 * @description
 * A model for an entities stakeholders.
 */
export const Stakeholders = [
  '$q',
  'SimpleHTTPWrapper',
  'BackendLocation',
  'ProcessUrlInfo',
  function ($q, SimpleHTTPWrapper, BackendLocation, ProcessUrlInfo) {
    const ENTITY_STAKEHOLDERS_URL = BackendLocation.entity('1') + 'stakeholders';
    const BACKEND_V2_URL = BackendLocation.entity('2');
    const STAKEHOLDERS_FETCH_ERROR = 'Could not load stakeholder infromation.';
    const FORM_FETCH_ERROR = 'Could not get stakeholder forms.';
    const STAKEHOLDER_CREATION_ERROR = 'Could not create stakeholder.';
    const STAKEHOLDER_AFFILIATES_ERROR = 'Could not find affiliates.';
    const SIMILAR_SH_ERROR = 'Could not load similar stakeholder.';
    const SH_APPROVAL_ERROR = 'Could not approve stakeholder.';
    const SH_REJECTION_ERROR = 'Could not reject stakeholder.';
    const SH_VERIFY_ERROR = 'Could not validate token';
    const PENDING_APPROVALS_ERROR = 'Failed to fetch pending approvals.';
    const SH_INVITATION_ERROR = 'Failed to send user invitation.';
    const SH_ADD_EMAIL_ERROR = 'Failed to attach email to user.';
    const SH_EDIT_ERROR = 'Failed to edit the user information.';

    return {
      /**
       * @ngdoc property
       * @name loading
       * @propertyOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Boolean describing if the async is outstanding.
       */
      loading: false,
      _loadingNum: 0,

      /**
       * @ngdoc property
       * @name permissions
       * @propertyOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Immutable map describing the current users stakholder permissions.
       */
      permissions: Map(),

      /**
       * @ngdoc property
       * @name persons
       * @propertyOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Immutable list of current personal stakeholders.
       */
      persons: List(),

      /**
       * @ngdoc property
       * @name entities
       * @propertyOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Immutable list of current entity stakeholders.
       */
      entities: List(),

      /**
       * @ngdoc property
       * @name stakes
       * @propertyOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Immutable list of current users entity stakes.
       */
      stakes: List(),

      _collection: Map(),

      _addToLookup(newStakeholders) {
        this._collection = this._collection.withMutations((collect) => {
          newStakeholders.forEach((sh) => {
            collect.set(sh.id, sh);
          });
        });
      },

      _addUniqueToCollection(collectionName, shData) {
        const collection = this[collectionName];
        const { id } = shData;
        const foundIndex = collection.findIndex((sh) => sh.id === id);
        if (foundIndex === -1) {
          this[collectionName] = collection.push(shData);
          return;
        }
        this[collectionName] = collection.set(foundIndex, shData);
      },

      _load(httpConfig, defaultError, resolution) {
        this._loadingNum += 1;
        this.loading = true;
        return SimpleHTTPWrapper(httpConfig, defaultError)
          .then(resolution)
          .finally(() => {
            this._loadingNum -= 1;
            this.loading = this._loadingNum !== 0;
          });
      },

      _getAffiliates(params) {
        return SimpleHTTPWrapper(
          {
            url: ENTITY_STAKEHOLDERS_URL + '/affiliates',
            params,
          },
          STAKEHOLDER_AFFILIATES_ERROR,
        );
      },

      _prepareStakeholderRequest(id, filterTerminated = true, securityTicketID) {
        const request = { url: ENTITY_STAKEHOLDERS_URL };
        if (filterTerminated || id || securityTicketID) {
          request.params = {
            ...(filterTerminated && { excludeStates: 'Employee : Terminated' }),
            ...(id && { 'ids:list': id }),
            securityTicketID,
          };
        }
        return request;
      },

      /**
       * @ngdoc method
       * @name getForms
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Fetch the forms (if they don't aready exist).
       *
       * @returns {promise} Promise that resolves with forms or rejects with
       *    a failure.
       */
      getForms() {
        if (this.forms) {
          return $q.resolve(this.forms);
        } else if (this._outstandingPopulate) {
          return this._outstandingPopulate.then(() => this.forms);
        }
        return this._load(
          {
            url: ENTITY_STAKEHOLDERS_URL + '/forms',
          },
          FORM_FETCH_ERROR,
          (data) => {
            this.forms = data;
            return data;
          },
        );
      },

      /**
       * @ngdoc method
       * @name get
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @param {string} id Stakeholder id
       *
       * @returns {object|undefined} Returns a stakeholder.
       */
      get(id) {
        return this._collection.get(id, undefined);
      },

      /**
       * @ngdoc method
       * @name create
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * This will create a new stakeholder and add the result to the model.
       *
       * @param {object} stakeholder Standard stakeholder widget model object.
       * @param {string} relationshipType Type for entity stakeholders.
       * @param {string} [asId=undefined] ID of the stakeholder to create.
       * @param {string} [creatorIsContact=undefined] Use the creator for contact info
       * @param {string} [forceEphemeral=undefined] Force the stakeholder to be ephemeral
       * @param {string} {createGuest=undefined} Create a guest stakeholder
       *
       * @returns {promise} Promise that resolves when completed or rejects with
       *    a failure. It will resolve with the stakeholder object itself.
       */
      create(
        stakeholder,
        relationshipType,
        asId,
        creatorIsContact,
        forceEphemeral,
        createGuest,
        securityTicketID,
      ) {
        const data = angular.copy(stakeholder);
        const hasAffiliate = Boolean(stakeholder.affiliate);
        // Backend likes the team string:
        data.type = (data.type || '').replace('Entity', 'Team');
        data.processId = ProcessUrlInfo.processId();
        data.relationshipType = relationshipType;
        data.asId = asId;
        data.creatorIsContact = creatorIsContact || false;
        data.forceEphemeral = forceEphemeral || false;
        data.createGuest = createGuest || false;
        data.securityTicketID = securityTicketID;
        return this._load(
          {
            method: 'POST',
            url: ENTITY_STAKEHOLDERS_URL,
            data,
          },
          STAKEHOLDER_CREATION_ERROR,
          (shData) => {
            this._collection = this._collection.set(shData.id, shData);
            if (!shData.teamStakeholder) {
              this._addUniqueToCollection('persons', shData);
            } else if (!hasAffiliate) {
              // Both stake and entity mean we can add to ent:
              this._addUniqueToCollection('entities', shData);
            }
            return shData;
          },
        );
      },

      /**
       * @ngdoc method
       * @name getAffiliates
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @param {string} id String id of affliates.
       *
       * @returns {promise} Promise that resolves with a list of affiliate strings
       *    or rejects with a failure.
       */
      getAffiliates(id) {
        return this._getAffiliates({ id });
      },

      /**
       * @ngdoc method
       * @name getAffiliatesOfEntity
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @param {string} entityId Foreign entity id of entity.
       *
       * @returns {promise} Promise that resolves with a list of affiliate strings
       *    or rejects with a failure.
       */
      getAffiliatesOfEntity(entityId) {
        return this._getAffiliates({ entityId });
      },

      /**
       * @ngdoc method
       * @name search
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Search stakeholders of the current entity.
       *
       * @param {string} query Search terms
       * @param {object} [options=] The options/filters of this query.
       *   @property {boolean} [allowStakes=false] Overrides results to be stakes.
       *   @property {boolean} [allowPerson=false] Allow person stakeholders.
       *   @property {boolean} [allowEntity=false] Allow entity stakeholders
       *   @property {boolean} [allowAffiliates=false] Allow affiliate stakeholders.
       *   @property {boolean} [withUsersOnly=false] Restrict stakeholders to those having a user only.
       *
       * @returns {promise} Promise that resolves with a list of similar stakeholder
       *    objects or rejects with a failure.
       */
      search(query, options = {}) {
        const {
          allowPerson,
          allowEntity,
          allowStakes,
          type,
          includeStates,
          excludeStates,
          includeGroups,
          onlyActive,
          withUsersOnly,
        } = options;
        let shClass;
        if (!allowEntity && !allowPerson && !allowStakes) {
          return $q.when([]);
        } else if (!allowPerson) {
          shClass = 'team';
        } else if (!allowEntity) {
          shClass = 'individual';
        }
        return SimpleHTTPWrapper(
          {
            url: ENTITY_STAKEHOLDERS_URL + '/search',
            params: {
              terms: query.trim().replace(/\s+/g, ' '),
              class: shClass,
              allowAffiliates: options.allowAffiliates,
              stakes: Boolean(allowStakes),
              states: includeStates ? includeStates.toString() : undefined,
              excludeStates: excludeStates ? excludeStates.toString() : undefined,
              includeGroups: includeGroups ? includeGroups.toString() : undefined,
              type: type,
              onlyActive: onlyActive,
              withUsersOnly: Boolean(withUsersOnly),
            },
          },
          SIMILAR_SH_ERROR,
        ).then(({ results }) => {
          this._addToLookup(results);
          return results;
        });
      },

      /**
       * @ngdoc method
       * @name findSimilarPersonStakeholders
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @param {string} firstName Stakeholder's first name.
       * @param {string} lastName Stakeholder's last name.
       *
       * @returns {promise} Promise that resolves with a list of similar stakeholder
       *    objects or rejects with a failure.
       */
      findSimilarPersonStakeholders(firstName, lastName) {
        return SimpleHTTPWrapper(
          {
            url: ENTITY_STAKEHOLDERS_URL + '/similar',
            params: { type: 'person', firstName, lastName },
          },
          SIMILAR_SH_ERROR,
        );
      },

      /**
       * @ngdoc method
       * @name findSimilarEntities
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Find similar entities (note that this is looking at raw entities not
       * just team stakeholders in within this particular entity).
       *
       * @param {string} entityTitle Entity title
       * @param {string} [affiliateTitle=undefined] Fund name
       * @param {boolean} [includeAffiliates] Should affiliates be included in
       *                                      results?
       *
       * @returns {promise} Promise that resolves with a list of similar stakeholder
       *    objects (might be empty) or rejects with a failure. Each returned
       *    stakeholder will have a potential `similarFunds` subArray.
       */
      findSimilarEntities(
        entityTitle,
        affiliateTitle,
        includeAffiliates,
        relationType,
        securityTicketID,
      ) {
        const params = {
          type: 'entity',
          entityTitle,
          affiliateTitle,
          relationType,
          securityTicketID,
        };
        if (includeAffiliates) {
          params.includeAffiliates = true;
        }
        return SimpleHTTPWrapper(
          {
            url: ENTITY_STAKEHOLDERS_URL + '/similar',
            params: params,
          },
          SIMILAR_SH_ERROR,
        );
      },

      /**
       * @ngdoc method
       * @name getStakeholder
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * This will hydrate the model with data if it hasn't already and find
       * a stakeholder with id (if it exists).
       *
       * @param {string} id Stakeholder id.
       * @param {boolean} filterTerminated add filter Employee : Terminated to request
       *
       * @returns {promise} Promise that resolves when completed or rejects with
       *    a failure.
       */
      getStakeholder(id, filterTerminated = true) {
        const founds = this.getStakeholders([id], filterTerminated);

        return founds
          .then((x) => {
            return x[0];
          })
          .catch(() => {
            return $q.reject('No stakeholder found.');
          });
      },

      /**
       * @ngdoc method
       * @name getStakeholders
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * This will hydrate the model with data if it hasn't already and find
       * a stakeholder with id (if it exists).
       *
       * @param {list of strings} ids Stakeholder id.
       * @param {boolean} filterTerminated add filter Employee : Terminated to request
       *
       * @returns {promise} Promise that resolves when completed or rejects with
       *    a failure.
       */
      getStakeholders(ids, filterTerminated = true) {
        const request = this._prepareStakeholderRequest(ids, filterTerminated);
        return SimpleHTTPWrapper(request, STAKEHOLDERS_FETCH_ERROR).then((data) => {
          this._addToLookup(data.persons);
          const persons = List(data.persons);
          this._addToLookup(data.entities);
          const entities = List(data.entities);
          const repEntities = this.entities.filter(
            (ent) =>
              ent.representatives.filter((rep) => ids.include(rep.id)).length > 0,
          );

          const founds = ids.reduce((res, id) => {
            // Also looking for representatives if given rep_id.
            // Only find one stakeholder per id
            const found =
              persons.find((sh) => sh.id === id) ||
              entities.find((sh) => sh.id === id) ||
              (repEntities.length &&
                repEntities.representatives.find((sh) => sh.id === id));

            if (found) {
              res.push(found);
            }
            return res;
          }, []);

          return founds.length ? founds : $q.reject('No stakeholders found.');
        });
      },

      /**
       * @ngdoc method
       * @name lockStakeholder
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Add a lock to stakeholder. This will prevent ephemeral stakeholder
       * from being deleted.
       *
       * @returns {promise} Promise that resolves when completed or rejects with
       *    a failure.
       */
      lockStakeholder(stakeholderId, lockId) {
        return this._load({
          method: 'POST',
          url: `${BACKEND_V2_URL}stakeholder/${stakeholderId}/lock?lockId=${lockId}`,
        });
      },

      /**
       * @ngdoc method
       * @name populate
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * This will hydrate the model with data for forms, persons, entites, stakes
       * and permissions.
       *
       * @returns {promise} Promise that resolves when completed or rejects with
       *    a failure.
       */
      populate(filterTerminated = true, ids, securityTicketID) {
        if (!this._outstandingPopulate) {
          const request = this._prepareStakeholderRequest(
            ids,
            filterTerminated,
            securityTicketID,
          );
          this._outstandingPopulate = this._load(
            request,
            STAKEHOLDERS_FETCH_ERROR,
            (data) => {
              this._addToLookup(data.persons);
              this.persons = List(data.persons);
              this._addToLookup(data.entities);
              this.entities = List(data.entities);
              this.stakes = List(data.stakes);
              this.permissions = Map(data.permissions);
              this.forms = data.forms;
            },
          ).finally(() => {
            this._outstandingPopulate = null;
          });
        }
        return this._outstandingPopulate;
      },

      /**
       * @ngdoc method
       * @name getPendingApprovals
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @return {promise} Resolves with a list of stakeholders, pending for
       *    approvals or rejects wih reason
       */
      getPendingApprovals() {
        return this._load(
          {
            url: ENTITY_STAKEHOLDERS_URL + '/approvals',
          },
          PENDING_APPROVALS_ERROR,
        );
      },

      /**
       * @ngdoc method
       * @name approve
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Approve stakeholder by given id.
       *
       * @param {string} id Id of stakeholder to approve.
       *
       * @return {promise} Resolves on successful approval and rejects on failure.
       */
      approve(id) {
        return this._load(
          {
            method: 'POST',
            url: ENTITY_STAKEHOLDERS_URL + `/${id}/approve`,
          },
          SH_APPROVAL_ERROR,
        );
      },

      /**
       * @ngdoc method
       * @name reject
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Reject stakeholder.
       *
       * @param {string} id Id of stakeholder to reject.
       * @param {string} [comment=undefined] ???
       *
       * @return {promise} Resolves on succes and rejects on failure.
       */
      reject(id, comment) {
        return this._load(
          {
            method: 'POST',
            url: ENTITY_STAKEHOLDERS_URL + `/${id}/reject`,
            data: { comment },
          },
          SH_REJECTION_ERROR,
        );
      },

      /**
       * @ngdoc method
       * @name isEmailVerified
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Check if a given email has been verified or not.
       *
       * @param {string} id Id of stakeholder to verify.
       * @param {string} email The email to send the verify to.
       *
       * @return {promise} Resolves on success and rejects on failure with a boolean.
       */
      isEmailVerified(id, email) {
        return SimpleHTTPWrapper(
          {
            url: `${ENTITY_STAKEHOLDERS_URL}/${id}/emails`,
            method: 'GET',
            data: { email },
          },
          SH_VERIFY_ERROR,
        ).then(
          (data) =>
            data.emails
              .filter((elm) => elm.value === email)
              .map((elm) => elm.isVerified)
              .reduce((_, elm) => elm),
          false,
        );
      },

      /**
       * @ngdoc method
       * @name invite
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Invite/remind stakeholder.
       *
       * @param {string} id Id of stakeholder to invite.
       * @param {string} [alternativeEmail=undefined] If provided, this
       *     email will be used instead of the stakeholder's default.
       * @param {string} [workitemId=undefined] If provided, the
       *     id of the workitem that the stakeholder is a performing (e.g.,
       *     in context of process status modal)
       * @param {object} [nameInfo=undefined] Name upate information (keys firstName,
       *   lastName, or title).
       *
       * @returns {promise} Resolves on success and rejects on failure.
       */
      invite(id, alternativeEmail, workitemId, nameInfo) {
        const data = Object.assign(
          {
            newEmail: alternativeEmail,
            workitemId,
          },
          nameInfo || {},
        );
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: ENTITY_STAKEHOLDERS_URL + `/${id}/invite`,
            data,
          },
          SH_INVITATION_ERROR,
        );
      },

      /**
       * @ngdoc method
       * @name editEternalized
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Edit stakeholder.
       *
       * @param {string} id Id of stakeholder to reject.
       * @param {string} [alternativeEmail=undefined] If provided, this
       *     email be used for the user.
       * @param {string} [workitemId=undefined] If provided, the
       *     id of the workitem that the stakeholder is a performing (e.g.,
       *     in context of process status modal)
       * @param {object} [nameInfo=undefined] Name upate information (keys firstName,
       *   lastName, or title).
       *
       * @returns {promise} Resolves on success and rejects on failure.
       */
      editEternalized(id, alternativeEmail, workitemId, nameInfo) {
        const data = Object.assign(
          {
            newEmail: alternativeEmail,
            workitemId,
          },
          nameInfo || {},
        );
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: ENTITY_STAKEHOLDERS_URL + `/${id}/editEternalized`,
            data,
          },
          SH_EDIT_ERROR,
        );
      },

      /**
       * @ngdoc method
       * @name addEmail
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Add initial email to stakeholder
       *
       * @param {string} id Id of stakeholder to reject.
       * @param {string} email Email address to assign
       *
       * @return {promise} Resolves on succes and rejects on failure.
       */
      addContactInfo(id, contactFields) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: ENTITY_STAKEHOLDERS_URL + `/${id}/add-contact-info`,
            data: contactFields,
          },
          SH_ADD_EMAIL_ERROR,
        );
      },

      /**
       * @ngdoc method
       * @name addEmail
       * @methodOf sb.lib.stakeholder.Stakeholders
       *
       * @description
       * Find privileges for stakeholders
       *
       * @param {string} id Id of stakeholder to reject.
       *
       * @return {promise} Resolves {grants: [{}], roles: [{}], access: [{}]}.
       */
      findPrivileges(id) {
        return SimpleHTTPWrapper(
          {
            method: 'POST',
            url: ENTITY_STAKEHOLDERS_URL + `/${id}/privileges`,
          },
          SH_ADD_EMAIL_ERROR,
        );
      },
    };
  },
];
