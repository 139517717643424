import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Observable, Subject, finalize, map, of, take, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'sbx-send-verification-button',
  templateUrl: './sbx-send-verification-button.component.html',
  styleUrl: './sbx-send-verification-button.component.scss',
})
export class SbxSendVerificationButtonComponent implements OnChanges, OnDestroy {
  @Input() public model: string;
  @Input() public timeout: number = 30;
  @Input() public loading: boolean = false;

  public buttonTitle$: Observable<string> = of('Send');

  private readonly stopCount$: Subject<void> = new Subject<void>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.stopCount$.next();
    }
    if (changes.loading && this.loading) {
      this.startButtonTimer();
    }
  }

  public ngOnDestroy(): void {
    this.stopCount$.next();
  }

  private startButtonTimer(): void {
    this.buttonTitle$ = timer(0, 1000).pipe(
      take(this.timeout + 1),
      takeUntil(this.stopCount$),
      map((count: number) => this.timeout - count),
      map((count: number) => `Resend in ${count} seconds`),
      finalize(() => {
        this.buttonTitle$ = of('Resend');
      }),
    );
  }
}
