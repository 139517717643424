<div class="sbx-header" (click)="toggleCollapsed()">
  <ng-container *ngIf="buttonTitle">
    <sbx-button
      theme="default"
      [icon]="collapsed ? 'triangleRight' : 'triangleDown'"
      [title]="buttonTitle"
    ></sbx-button>
  </ng-container>

  <ng-container *ngIf="!buttonTitle">
    <sbx-icon
      [type]="collapsed ? 'triangleRight' : 'triangleDown'"
      class="sbx-collapsable-icon"
    ></sbx-icon>
  </ng-container>

  <ng-container *ngIf="titleTemplate">
    <ng-template
      *ngTemplateOutlet="titleTemplate; context: { $implicit: titleTemplateContext }"
    ></ng-template>
  </ng-container>

  <ng-container *ngIf="!titleTemplate">
    {{ title }}
  </ng-container>
</div>
<div
  class="sbx-content"
  [ngClass]="{ 'sbx-animated': animated }"
  [style.maxHeight]="animated ? (!collapsed ? maxHeight : '0px') : 'inherit'"
  #contentRef
>
  <ng-content *ngIf="!collapsed"></ng-content>
</div>
