import { Injectable, Inject } from '@angular/core';
import { AppConfig } from '../config/app.config';
import { IContextDependent } from './interfaces';

/**
 *  Helper abstraction for accessing API endpoints.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class BackendLocation implements IContextDependent<string> {
  constructor(@Inject(AppConfig) private appConfig: AppConfig) {}

  private getUrl(version: string) {
    const url = this.appConfig.endpointInfos[version];

    if (!url) {
      throw new Error('Unknown backend version: ' + version);
    }

    return url;
  }

  /**
   * Return stakeholder for current user in this entity
   */
  onBehalf(version: string): string {
    if (this.appConfig.currentLens) {
      return this.appConfig.currentLens?.onbehalf;
    }
    return this.appConfig.endpointInfos[version].onbehalf;
  }

  /**
   *  Returns the entity-based URL for a given version.
   */
  entity(version: string): string {
    return this.getUrl(version).entity;
  }

  /**
   *  Returns the root-based URL for a given version.
   */
  root(version: string): string {
    return this.getUrl(version).root;
  }

  /**
   *  Returns the current context URL for a given version.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  context(version: string): string {
    throw new Error('Context is not supported, please use AngularJS version');
  }
}
