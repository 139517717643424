<div class="sbx-adjust-taxes">
  <table>
    <thead>
      <tr class="sbx-event-row sbx-tall-row">
        <th class="sbx-event-empty-header"></th>
        <th class="sbx-event-header">Calculated Taxes</th>
        <th class="sbx-event-header">Adjusted Taxes</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let row of rows"
        [class]="'sbx-event-row' + (row?.adjustment?.canEdit ? '' : ' sbx-tall-row')"
      >
        <td class="sbx-event-name subfield-{{ row.subfield }}">
          {{ row.adjustment !== null ? row.adjustment.title : row.group }}
        </td>
        <td class="sbx-event-old-value sbx-bold">
          <span *ngIf="row.adjustment !== null && row?.adjustment.tax !== null">{{
            row.adjustment?.tax | currency
          }}</span>
        </td>
        <td class="sbx-event-value">
          <div class="sbx-form-field" *ngIf="row.adjustment !== null">
            <div class="sbx-form-field-input-addon sbx-prefix">$</div>
            <sbx-number-textline-base
              [model]="row.adjustment.override"
              [to]="{ readOnly: !row.adjustment.canEdit }"
              [precision]="2"
              [showError]="row.showError"
              (focus)="handleFocus(row)"
              (blur)="handleBlur(row)"
              (changeValue)="handleChange(row, $event)"
            ></sbx-number-textline-base>
          </div>
          <div *ngIf="row.showError" class="sbx-form-field-error">
            Value must be at least 0.
          </div>
          <div class="sbx-event-remove" *ngIf="row.canRemove" (click)="removeRow(row)">
            <sbx-icon type="remove"></sbx-icon>
          </div>
        </td>
      </tr>
      <tr *ngIf="canAdd" class="sbx-event-row">
        <td class="sbx-event-value">
          <div class="sbx-action">
            <sbx-button
              title="Add Tax Entry"
              icon="plus"
              (click)="addCustomEntry()"
            ></sbx-button>
          </div>
        </td>
      </tr>

      <tr class="sbx-event-row sbx-tall-row">
        <td class="sbx-event-name sbx-bold">Total Estimated Taxes</td>
        <td class="sbx-event-old-value"></td>
        <td class="sbx-event-value sbx-bold">
          <div class="sbx-total-taxes-value sbx-bold">
            <span *ngIf="totalTaxes !== null">{{ totalTaxes | currency }}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
