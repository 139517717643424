import { Injectable } from '@angular/core';
import { SbxHttpClient } from '@/core/http';
import { Error as ErrorResponse } from '@shoobx/types/webapi-v2';
import { Observable, catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

const API_VERSION: string = '2';
const API_URI: string = 'domains';
const SEND_TOKEN_ERROR = 'Could not send verification code.';
const VERIFY_ERROR = 'Could not validate token.';
const RESET_ERROR = 'Could not reset domain.';

@Injectable({
  providedIn: 'root',
})
export class SbxEntityDomainVerificationService {
  public constructor(private readonly sbxHttpClient: SbxHttpClient) {}

  public challenge(email: string): Observable<Record<string, never>> {
    return this.sbxHttpClient
      .entity(API_VERSION)
      .put<Record<string, never>>(API_URI, {
        params: { email },
      })
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.handleError(error?.error, SEND_TOKEN_ERROR),
        ),
      );
  }

  public verify(token: string): Observable<Record<string, never>> {
    return this.sbxHttpClient
      .entity(API_VERSION)
      .post<Record<string, never>>(`${API_URI}/verify`, {
        params: { token },
      })
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.handleError(error?.error, VERIFY_ERROR),
        ),
      );
  }

  public reset(): Observable<Record<string, never>> {
    return this.sbxHttpClient
      .entity(API_VERSION)
      .delete<Record<string, never>>(API_URI)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.handleError(error?.error, RESET_ERROR),
        ),
      );
  }

  private handleError(error: ErrorResponse, defaultMessage: string): Observable<never> {
    return throwError(() => new Error(error?.message || defaultMessage));
  }
}
