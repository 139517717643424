import { fromEvent } from 'rxjs';

const STRIPE_STYLES = Object.freeze({
  base: {
    fontSize: '16px',
    fontFamily: '"Source Sans Pro", Helvetica, Arial, sans-serif',
    color: '#141414',
    '::placeholder': {
      color: '#97AAAA',
    },
  },
  invalid: {
    color: '#141414',
    iconColor: '#FF0000',
  },
});

/**
 * @ngdoc component
 * @name sb.billing.component:sbStripeElement
 *
 * @description
 * This element is for the stripe element (or where the user types in the card information itself).
 *
 * @param {expression} ngModel Model expression.
 */
export const sbStripeElement = {
  controllerAs: 'vm',
  template: '',
  require: {
    ngModelCtrl: 'ngModel',
  },
  bindings: {
    model: '=ngModel',
  },
  controller: [
    '$scope',
    '$element',
    '$observable',
    '$stripe',
    'sbxZoneService',
    function ($scope, $element, $observable, $stripe, sbxZoneService) {
      function $postLink() {
        $stripe.then((api) => {
          sbxZoneService.getZone().runOutsideAngular(() => {
            const elements = api.elements();
            const card = elements.create('card', { style: STRIPE_STYLES });
            // We wrap this in a getter function because watchers would otherwise go insane.
            this.model = { getCard: () => card };
            card.mount($element[0]);
          });

          const { ngModelCtrl } = this;
          ngModelCtrl.$setValidity('stripe', false);
          const card = this.model.getCard();
          fromEvent(card, 'change').$applySubscribe($scope, ({ error, complete }) => {
            const errorMessage = error && error.message;
            ngModelCtrl.$setValidity('stripe', !errorMessage);
            if (ngModelCtrl.$validators.required) {
              ngModelCtrl.$setValidity('required', complete);
            }
            ngModelCtrl.$setTouched();
          });
        });
      }
      this.$postLink = $postLink.bind(this);
    },
  ],
}; // end sbStripeElement

/**
 * @ngdoc object
 * @name sb.lib.billing:$stripe
 *
 * @description
 * This is a promise that will resolve when stripe has been asyncronously loaded in. The resolution
 * of the promise will be the Shoobx instance of the Stripe API (already loaded with API keys).
 */
export const $stripe = [
  '$window',
  '$q',
  'AppConfig',
  'sbxZoneService',
  function ($window, $q, AppConfig, sbxZoneService) {
    const STRIPE_JS_URL = 'https://js.stripe.com/v3/';
    return $q(async (resolve) => {
      const key = await AppConfig.fetchLicenseKey('stripe');
      const scriptTag = $window.document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.src = STRIPE_JS_URL;
      scriptTag.async = true;
      scriptTag.addEventListener('load', () => {
        sbxZoneService.getZone().runOutsideAngular(() => {
          const ourStripe = $window.Stripe(key);
          resolve(ourStripe);
        });
      });
      // would be nice to use destructuring assignment here, but we can't due to IE bug
      const head = $window.document.getElementsByTagName('head')[0];
      head.appendChild(scriptTag);
    });
  },
]; // end $stripe
