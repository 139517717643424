import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import WebViewer, {
  WebViewerInstance,
  type Core as WebViewerInstanceCore,
} from '@pdftron/webviewer';
import { Downgrade } from '../downgrade';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { initializeCustomRectangleAnnotation } from './pdftron';
import { AppConfig } from '@/core/config/app.config';

interface IField {
  id: string;
  pageNumber: number;
  left: number;
  top: number;
  width: number;
  height: number;
  title?: string;
  selected?: boolean;
}

@Downgrade.Component('ngShoobx', 'sbx-pdf-viewer')
@Component({
  selector: 'sbx-pdf-viewer',
  templateUrl: './sbx-pdf-viewer.component.html',
  styleUrls: ['./sbx-pdf-viewer.component.scss'],
})
export class SbxPdfViewerComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input({ required: true }) url: string = '';
  @Input({ required: true }) mode: 'view' | 'draw' | 'fill' = 'view';
  @Input() canDownload: boolean = false;
  @Input() drawingId: string = '';
  @Input() drawingFields: { [key: string]: IField } = {};
  @Input() fillingValues: { [key: string]: string | boolean } = {};
  @Input() fillingErrors: { [key: string]: { name: string; message: string } } = {};

  @Output() drawingFieldAdd = new EventEmitter<IField>();
  @Output() drawingFieldModify = new EventEmitter<IField>();
  @Output() drawingFieldDelete = new EventEmitter<Pick<IField, 'id'>>();
  @Output() drawingFieldSelect = new EventEmitter<Pick<IField, 'id'>>();
  @Output() fillingValueChange = new EventEmitter<{
    key: string;
    value: string | boolean;
  }>();

  // Do not use directly, use `await this.getWebViewerInstance()` instead.
  instance: BehaviorSubject<WebViewerInstance | null> = new BehaviorSubject(null);
  disableAnnotationSelectedEvent = false;
  customRectangleAnnotation: ReturnType<typeof initializeCustomRectangleAnnotation>;

  constructor(
    @Inject(AppConfig) private appConfig: AppConfig,
    @Inject(ElementRef) private elementRef: ElementRef,
  ) {}

  ngAfterViewInit() {
    this.initializeWebViewerInstance();

    switch (this.mode) {
      case 'draw': {
        this.setupAnnotationsDrawing();
        break;
      }

      case 'fill': {
        this.setupAnnotationsFilling();
        break;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    switch (this.mode) {
      case 'draw': {
        if (!changes.drawingId?.previousValue && changes.drawingId?.currentValue) {
          this.startFieldDrawing();
        }

        if (changes.drawingId?.previousValue && !changes.drawingId?.currentValue) {
          this.endFieldDrawing();
        }

        if (changes.drawingFields?.currentValue) {
          this.updateAnnotationsTitles();

          const currentDrawingFields = changes.drawingFields?.currentValue as {
            [key: string]: IField;
          };
          const selectedField = Object.values(currentDrawingFields).find(
            (field) => field.selected,
          );

          if (selectedField) {
            this.selectAnnotation(selectedField.id);
          }

          const previousKeys = Object.keys(changes.drawingFields.previousValue);
          const currentKeys = Object.keys(changes.drawingFields.currentValue);

          if (previousKeys.length > currentKeys.length) {
            previousKeys.forEach((key) => {
              if (!currentKeys.includes(key)) {
                this.deleteAnnotation(key);
              }
            });
          }
        }

        break;
      }

      case 'fill': {
        const errorsKeys = Object.keys(changes.fillingErrors?.currentValue ?? {});

        if (errorsKeys.length) {
          this.scrollToFirstAnnotation(errorsKeys);
        }

        break;
      }
    }
  }

  async ngOnDestroy() {
    const { UI } = await this.getWebViewerInstance();
    UI.dispose();
  }

  async initializeWebViewerInstance() {
    const key = await this.appConfig.fetchLicenseKey('pdftron');

    const instance = await WebViewer.Iframe(
      {
        path: '/@@/pdftron',
        licenseKey: key,
        initialDoc: this.url,
        extension: 'pdf',
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        css: require('./pdftron.css?rawFile'),
        disableLogs: process.env.NODE_ENV === 'production',
      },
      this.elementRef.nativeElement,
    );

    this.setupWebViewerInstanceDefaults(instance);
    this.customRectangleAnnotation = initializeCustomRectangleAnnotation(instance);

    const { Core } = instance;

    await new Promise((resolve) => {
      Core.documentViewer.addEventListener(
        Core.DocumentViewer.Events.DOCUMENT_LOADED,
        resolve,
      );
    });

    this.adjustFitMode(instance);

    this.instance.next(instance);
  }

  getWebViewerInstance() {
    return firstValueFrom(this.instance.pipe(filter((instance) => instance !== null)));
  }

  setupWebViewerInstanceDefaults(instance: WebViewerInstance) {
    const { Core, UI } = instance;

    // Disable AcroJS because our CSP policy doesn't allow `script-src 'unsafe-inline'`
    Core.disableEmbeddedJavaScript();

    UI.disableFeatures(Object.values(UI.Feature));
    UI.disableTools(Object.values(Core.Tools.ToolNames) as string[]);
    UI.disableElements(['textPopup', 'annotationPopup', 'contextMenuPopup']);
    UI.disableElements([
      'leftPanelTabs',
      'thumbnailsSizeSlider',
      'thumbnailControl',
      'documentControl',
      'leftPanelResizeBar',
    ]);
    UI.hotkeys.off();

    UI.enableFeatures([UI.Feature.Search]);
    UI.enableTools([
      Core.Tools.ToolNames.PAN as unknown as string,
      Core.Tools.ToolNames.EDIT as unknown as string,
    ]);

    UI.setHeaderItems((header) => {
      const items = [...(header.getItems() as { [key: string]: unknown }[])];
      items.splice(
        items.findIndex((i) => i.dataElement === 'menuButton'),
        2,
      );

      if (this.canDownload) {
        items.push({
          type: 'actionButton',
          img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:#abb0c4;}</style></defs><title>icon - header - download</title><path class="cls-1" d="M11.55,17,5.09,9.66a.6.6,0,0,1,.45-1H8.67V2.6a.6.6,0,0,1,.6-.6h5.46a.6.6,0,0,1,.6.6V8.67h3.13a.6.6,0,0,1,.45,1L12.45,17A.6.6,0,0,1,11.55,17ZM3.11,20.18V21.6a.4.4,0,0,0,.4.4h17a.4.4,0,0,0,.4-.4V20.18a.4.4,0,0,0-.4-.4h-17A.4.4,0,0,0,3.11,20.18Z"></path></svg>',
          onClick: () => (window.location.href = this.url),
        });
      }

      header.update(items);
    });
    UI.setLayoutMode(UI.LayoutMode.Continuous);
  }

  adjustFitMode(instance: WebViewerInstance) {
    const { UI } = instance;

    UI.setFitMode(UI.FitMode.FitWidth);

    // Fix X axis scrollbar after setting fit mode to UI.FitMode.FitWidth by rounding
    // down and substracting 1%.
    const adjustedZoomLevel = Math.floor(UI.getZoomLevel() * 100) / 100 - 0.01;

    if (adjustedZoomLevel > 0) {
      UI.setZoomLevel(adjustedZoomLevel);
    }
  }

  async setupAnnotationsDrawing() {
    const { Core } = await this.getWebViewerInstance();

    await Core.documentViewer.getAnnotationsLoadedPromise();

    Object.values(this.drawingFields).forEach((field) => {
      // Old pdf.js counts pageNumber from zero
      const pageNumber = field.pageNumber + 1;
      const document = Core.documentViewer.getDocument();
      const viewerCoordinates = document.getViewerCoordinates(
        pageNumber,
        field.left,
        field.top + field.height,
      );
      const annotation = this.customRectangleAnnotation.create({
        X: viewerCoordinates.x,
        Y: viewerCoordinates.y,
        Width: field.width,
        Height: field.height,
        PageNumber: pageNumber,
      });
      annotation.setCustomData('key', field.id);
      annotation.setCustomData('title', field.title);
      annotation.disableRotationControl();
      Core.annotationManager.addAnnotation(annotation);
      Core.annotationManager.redrawAnnotation(annotation);
    });

    Core.annotationManager.addEventListener(
      Core.AnnotationManager.Events.ANNOTATION_CHANGED,
      (annotations, action, info) => {
        if (info.source === 'outsideChange') {
          return;
        }

        annotations.forEach((annotation) => {
          switch (action) {
            case 'add': {
              annotation.setCustomData('key', this.drawingId);
              annotation.disableRotationControl();

              const document = Core.documentViewer.getDocument();
              const { x, y } = document.getPDFCoordinates(
                annotation.PageNumber,
                annotation.X,
                annotation.Y,
              );

              this.drawingFieldAdd.emit({
                id: annotation.getCustomData('key'),
                pageNumber: annotation.PageNumber - 1,
                left: x,
                top: y - annotation.Height,
                width: annotation.Width,
                height: annotation.Height,
              });
              break;
            }

            case 'modify': {
              const document = Core.documentViewer.getDocument();
              const { x, y } = document.getPDFCoordinates(
                annotation.PageNumber,
                annotation.X,
                annotation.Y,
              );

              this.drawingFieldModify.emit({
                id: annotation.getCustomData('key'),
                pageNumber: annotation.PageNumber - 1,
                left: x,
                top: y - annotation.Height,
                width: annotation.Width,
                height: annotation.Height,
              });
              break;
            }

            case 'delete': {
              this.drawingFieldDelete.emit({
                id: annotation.getCustomData('key'),
              });
              break;
            }
          }
        });
      },
    );

    Core.annotationManager.addEventListener(
      Core.AnnotationManager.Events.ANNOTATION_SELECTED,
      (annotations, action) => {
        if (this.disableAnnotationSelectedEvent) {
          return;
        }

        switch (action) {
          case 'selected': {
            // Prevent user from selecting more than one annotation
            if (annotations.length > 1) {
              Core.annotationManager.deselectAllAnnotations();
            }

            if (annotations.length === 1) {
              const annotation = annotations[0];

              this.drawingFieldSelect.emit({
                id: annotation.getCustomData('key'),
              });
            }
            break;
          }

          case 'deselected': {
            if (Core.annotationManager.getSelectedAnnotations().length === 0) {
              this.drawingFieldSelect.emit({
                id: '',
              });
            }
            break;
          }
        }
      },
    );
  }

  async setupAnnotationsFilling() {
    const { Core } = await this.getWebViewerInstance();

    await Core.documentViewer.getAnnotationsLoadedPromise();

    const fieldManager = Core.annotationManager.getFieldManager();

    fieldManager.getFields().forEach((field) => {
      const value = this.fillingValues[field.name];

      switch (value) {
        case true:
          field.setValue('Yes');
          break;
        case false:
          field.setValue('Off');
          break;
        default:
          field.setValue(value);
      }
    });

    Core.annotationManager.addEventListener(
      Core.AnnotationManager.Events.FIELD_CHANGED,
      (field) => {
        switch (field.getFieldType()) {
          case 'TextFormField':
            this.fillingValueChange.emit({
              key: field.name,
              value: field.getValue(),
            });
            break;
          case 'CheckBoxFormField':
            this.fillingValueChange.emit({
              key: field.name,
              value: field.getValue() !== 'Off',
            });
            break;
          case 'RadioButtonFormField':
            this.fillingValueChange.emit({
              key: field.name,
              value: field.getValue() === 'Off' ? false : field.getValue(),
            });
            break;
          default:
            throw new Error(`Field type "${field.getFieldType()}" is not supported`);
        }
      },
    );
  }

  async startFieldDrawing() {
    const { UI } = await this.getWebViewerInstance();

    UI.setToolbarGroup(UI.ToolbarGroup.ANNOTATE);
    UI.setToolMode(this.customRectangleAnnotation.toolName);
  }

  async endFieldDrawing() {
    const { UI } = await this.getWebViewerInstance();

    UI.setToolbarGroup(UI.ToolbarGroup.VIEW);
  }

  async updateAnnotationsTitles() {
    const { Core } = await this.getWebViewerInstance();

    await Core.documentViewer.getAnnotationsLoadedPromise();

    const annotations = Core.annotationManager.getAnnotationsList();
    annotations.forEach((annotation) => {
      const fieldName = annotation.getCustomData('key');
      const fieldTitle = annotation.getCustomData('title');
      const field = this.drawingFields[fieldName];

      // Check if field exists first, it can happen that field is deleted in the
      // sidebar, but annotation is not deleted yet
      if (!field || field.title === fieldTitle) {
        return;
      }

      annotation.setCustomData('title', field.title);
      Core.annotationManager.redrawAnnotation(annotation);
    });
  }

  async selectAnnotation(key: string) {
    const { Core } = await this.getWebViewerInstance();

    await Core.documentViewer.getAnnotationsLoadedPromise();

    const annotations = Core.annotationManager.getAnnotationsList();
    const annotation = annotations.find((annotation) => {
      const fieldName = annotation.getCustomData('key');

      // Special treatment for checkboxes group field. Backend sends partial key, but
      // annotation.fieldName has full key.
      return [key].some((key) => fieldName.startsWith(key));
    });

    if (!Core.annotationManager.isAnnotationSelected(annotation)) {
      this.disableAnnotationSelectedEvent = true;

      Core.annotationManager.deselectAllAnnotations();
      Core.annotationManager.selectAnnotation(annotation);

      this.disableAnnotationSelectedEvent = false;
    }

    Core.annotationManager.jumpToAnnotation(annotation);
  }

  async deleteAnnotation(key: string) {
    const { Core } = await this.getWebViewerInstance();

    await Core.documentViewer.getAnnotationsLoadedPromise();

    const annotations = Core.annotationManager.getAnnotationsList();
    const annotation = annotations.find((annotation) => {
      const fieldName = annotation.getCustomData('key');

      // Special treatment for checkboxes group field. Backend sends partial key, but
      // annotation.fieldName has full key.
      return [key].some((key) => fieldName.startsWith(key));
    });

    Core.annotationManager.deleteAnnotation(annotation, {
      source: 'outsideChange',
    });
  }

  async scrollToFirstAnnotation(keys: string[]) {
    const { Core } = await this.getWebViewerInstance();

    await Core.documentViewer.getAnnotationsLoadedPromise();

    const annotations = Core.annotationManager.getAnnotationsList();
    const annotation = annotations.find((annotation) => {
      const fieldName = (
        annotation as WebViewerInstanceCore.Annotations.WidgetAnnotation
      ).fieldName;

      // Special treatment for checkboxes group field. Backend sends partial key, but
      // annotation.fieldName has full key.
      return keys.some((key) => fieldName.startsWith(key));
    });

    if (!annotation) {
      return;
    }

    Core.annotationManager.jumpToAnnotation(annotation);
  }
}
