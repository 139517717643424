import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Downgrade } from '@/shared/downgrade';

@Downgrade.Component('ngShoobx', 'sbx-collapsable')
@Component({
  selector: 'sbx-collapsable',
  templateUrl: './sbx-collapsable.component.html',
  styleUrls: ['./sbx-collapsable.component.scss'],
})
export class SbxCollapsableComponent implements AfterViewInit {
  @Input() public title = '';
  @Input() public buttonTitle = '';
  @Input() public titleTemplate: TemplateRef<unknown> = null;
  @Input() public titleTemplateContext: unknown = null;
  @Input() public collapsed = false;
  @Input() public animated: boolean = false;
  @Input() public accordion: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public change = new EventEmitter<boolean>();
  public maxHeight: string = '0px';

  @ViewChild('contentRef') public contentRef!: ElementRef;

  @HostBinding('class.sbx-accordion') get isAccordion(): boolean {
    return this.accordion;
  }

  public ngAfterViewInit(): void {
    if (this.animated) {
      requestAnimationFrame(() => {
        const contentElement = this.contentRef.nativeElement;
        this.maxHeight = `${contentElement.scrollHeight}px`;
      });
    }
  }

  public toggleCollapsed(): void {
    if (!this.animated) {
      this.collapsed = !this.collapsed;
      this.change.emit(this.collapsed);
      return;
    }
    const contentElement = this.contentRef.nativeElement;
    if (this.collapsed) {
      this.collapsed = !this.collapsed;
      this.change.emit(this.collapsed);
      requestAnimationFrame(() => {
        const contentElement = this.contentRef.nativeElement;
        this.maxHeight = `${contentElement.scrollHeight}px`;
      });
    } else {
      contentElement.addEventListener(
        'transitionend',
        this.onTransitionEnd.bind(this),
        { once: true },
      );
      this.maxHeight = '0px';
    }
  }

  private onTransitionEnd(event: TransitionEvent): void {
    if (event.propertyName === 'max-height') {
      this.collapsed = !this.collapsed;
      this.change.emit(this.collapsed);
    }
  }
}
