import angular from 'angular';
import Modal from '../modal/modal';
import sbBootstrap from './../bootstrap/bootstrap';
import Form from './../form/form';
import URL from '../url';
import Tools from '../tools';
import RX from '../rx';

import { sbInviteButton, $stakeholderInviteModal } from './invite';
import {
  $createStakeholderModal,
  $similarEntitiesModal,
  $addEmailModal,
  similarEntitiesModalHelper,
} from './modal';
import {
  sbStakeholderForm,
  sbAffiliateForm,
  sbSimilarStakeholders,
  sbSameAffiliateWarning,
  entityFilter,
  sbStakeholderCreationForm,
} from './form';
import { sbStakeholdersEvents, sbStakeholderEventHistory } from './stakeholder-events';
import { Stakeholders, StakeholderEvents } from './service';
import { sbStakeholderPrivilegePage } from './stakeholder-privilege';

/**
 * @ngdoc overview
 * @name sb.lib.stakeholder
 *
 * @description
 * Set of generic, reusable stakeholder-related UI components
 */
export default angular
  .module('sb.lib.stakeholder', [Modal, Form, URL, sbBootstrap, Tools, RX])
  .component('sbInviteButton', sbInviteButton)
  .component('sbSimilarStakeholders', sbSimilarStakeholders)
  .component('sbSameAffiliateWarning', sbSameAffiliateWarning)
  .component('sbStakeholderForm', sbStakeholderForm)
  .component('sbAffiliateForm', sbAffiliateForm)
  .component('sbStakeholdersEvents', sbStakeholdersEvents)
  .component('sbStakeholderCreationForm', sbStakeholderCreationForm)
  .component('sbStakeholderEventHistory', sbStakeholderEventHistory)
  .component('sbStakeholderPrivilegePage', sbStakeholderPrivilegePage)
  .factory('StakeholderEvents', StakeholderEvents)
  .factory('Stakeholders', Stakeholders)
  .factory('$createStakeholderModal', $createStakeholderModal)
  .factory('$addEmailModal', $addEmailModal)
  .factory('$similarEntitiesModal', $similarEntitiesModal)
  .factory('similarEntitiesModalHelper', similarEntitiesModalHelper)
  .factory('$stakeholderInviteModal', $stakeholderInviteModal)
  .filter('entityFilter', entityFilter).name;
