import { Injectable, Inject } from '@angular/core';
import {
  filter,
  first,
  interval,
  Observable,
  switchMap,
  takeUntil,
  throwError,
  timer,
} from 'rxjs';
import { SbxHttpClient, BackendLocation } from '@/core/http';
import {
  TaxReport,
  TaxesSummary,
  PickupFile,
  GenericCreateForm,
  GenericStatusResponse,
  TaxEventAdjustments,
  TaxAdjustment,
  IsReportUpToDateResult,
  TaxAdjustmentOptionsSummary,
} from '@shoobx/types/webapi-v2';

export interface IReportOptions {
  offset: number;
  limit: number;
  sortKey: string;
  sortOrder: string;
  eventTypes: Array<string>;
  startDate: string;
  endDate: string;
  stakeholderId: string;
  country: string;
}

@Injectable({ providedIn: 'root' })
export class SbxTaxService {
  API_VERSION = '2';

  constructor(
    @Inject(SbxHttpClient) public sbxHttpClient: SbxHttpClient,
    @Inject(BackendLocation) public backendLocation: BackendLocation,
  ) {}

  getTaxReport(params: IReportOptions): Observable<TaxReport> {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<TaxReport>('tax/reports/taxes', { params: params });
  }

  export(params: IReportOptions): Observable<PickupFile> {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<PickupFile>('tax/reports/taxes.xlsx', { params: params });
  }

  getEventsSummary(
    documentId: string,
    eventIds: Array<string>,
  ): Observable<TaxesSummary> {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<TaxesSummary>(`tax/events/${documentId}/summary`, {
        params: { events: eventIds },
      });
  }

  getReportSettingsForm(): Observable<GenericCreateForm> {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<GenericCreateForm>(`tax/reports/taxes/settings-form`);
  }

  getEventAdjustmentUrl(documentId: string, eventId: string): string {
    return `tax/events/${documentId}/${eventId}/adjustments`;
  }

  getWorkitemEventAdjustmentUrl(
    workitemId: string,
    documentId: string,
    eventId: string,
  ): string {
    return `workitems/${workitemId}/tax/events/${documentId}/${eventId}/adjustments`;
  }

  getEventAdjustmentOptions(url: string): Observable<TaxAdjustmentOptionsSummary> {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .get<TaxAdjustmentOptionsSummary>(url);
  }

  postEventAdjustments(
    url: string,
    taxes: TaxAdjustment[],
    reason: string,
    taxesCollected: boolean,
  ): Observable<GenericStatusResponse> {
    return this.sbxHttpClient
      .entity(this.API_VERSION)
      .post<GenericStatusResponse>(url, {
        params: <TaxEventAdjustments>{ taxes, reason, taxesCollected },
      });
  }

  waitUntilReportUptoDate(): Observable<unknown> {
    return interval(500).pipe(
      switchMap(() => {
        return this.sbxHttpClient
          .entity(this.API_VERSION)
          .get<IsReportUpToDateResult>('reports/tax/is-up-to-date');
      }),
      filter((result: IsReportUpToDateResult) => result.isUpToDate === true),
      first(),
      takeUntil(
        timer(30000).pipe(switchMap(() => throwError(() => new Error('Timeout!')))),
      ),
    );
  }
}
