import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';
import { Downgrade } from '../../downgrade';
import { SbxEntityDomainVerificationService } from './sbx-entity-domain-verification.service';

const SELECTOR = 'sbx-verification-base';
@Downgrade.Component('ngShoobx', SELECTOR)
@Component({
  selector: SELECTOR,
  templateUrl: './sbx-verification-base.component.html',
  styleUrl: './sbx-verification-base.component.scss',
})
export class SbxVerificationBaseComponent implements OnChanges {
  @Input() public name: string;

  @Input() public model: string;

  @Output()
  public readonly modelChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() public isVerified: boolean;

  @Output()
  public readonly isVerifiedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public subtype: string;

  @Input() public maskedAccount: string;

  @Output()
  public readonly verifyEvent: EventEmitter<string> = new EventEmitter<string>();

  public readonly emailForm: FormGroup = new FormGroup({});
  public readonly emailFormFields: FormlyFieldConfig[] = [
    {
      key: 'verificationEmail',
      type: 'email-textline',
      templateOptions: {
        placeholder: 'Enter Email Address',
        required: true,
      },
    },
  ];
  public readonly emailFormModel = {
    verificationEmail: '',
  };

  public readonly codeForm: FormGroup = new FormGroup({});
  public readonly codeFormFields: FormlyFieldConfig[] = [
    {
      key: 'verificationCode',
      type: 'string-textline',
      templateOptions: {
        placeholder: 'Enter Verification Code',
        required: true,
      },
    },
  ];
  public readonly codeFormModel = {
    verificationCode: '',
  };

  public readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public readonly error$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly warning$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public showSent: boolean = false;

  public constructor(
    private readonly entityDomainVerification: SbxEntityDomainVerificationService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.subtype && this.subtype !== 'domainEmail') {
      throw new Error(
        'Subtypes other than domainEmail are not supported. The passed subtype = "' +
          this.subtype +
          '"',
      );
    }
    if (changes.isVerified && typeof this.isVerified === 'string') {
      this.isVerified = this.isVerified === 'true';
    }
  }

  public submitEmail(): void {
    this.loading$.next(true);
    this.clearAlerts();
    this.challengeEntityDomain();
  }

  public submitCode(): void {
    this.loading$.next(true);
    this.clearAlerts();
    this.verifyEntityDomain();
  }

  public submitRemove(): void {
    this.loading$.next(true);
    this.clearAlerts();
    this.emailFormModel.verificationEmail = '';
    this.codeFormModel.verificationCode = '';
    this.showSent = false;
    this.resetEntityDomain();
  }

  private challengeEntityDomain(): void {
    this.entityDomainVerification.challenge(this.model).subscribe({
      next: () => {
        this.loading$.next(false);
        this.showSent = true;
      },
      error: (error: Error) => this.handleError(error.message),
    });
  }

  private verifyEntityDomain(): void {
    this.entityDomainVerification
      .verify(this.codeFormModel.verificationCode)
      .subscribe({
        next: () => {
          this.loading$.next(false);
          this.isVerified = true;
          this.isVerifiedChange.emit(true);
        },
        error: (error: Error) => this.handleError(error.message),
      });
  }

  private resetEntityDomain(): void {
    this.entityDomainVerification.reset().subscribe({
      next: () => {
        this.loading$.next(false);
        this.isVerified = false;
        this.isVerifiedChange.emit(false);
      },
      error: (error: Error) => this.handleError(error.message),
    });
  }

  public handleError(message: string): void {
    this.loading$.next(false);
    this.error$.next(message);
  }

  private clearAlerts(): void {
    this.error$.next('');
    this.warning$.next('');
  }
}
